export const TOAST_DURATION = 5000

export enum API_TYPES {
  QUESTIONNAIRE_API = 'QUESTIONNAIRE_API',
  PROJECT_API = 'PROJECT_API',
  APP_NAMESPACE = 'APP_NAMESPACE',
}

const developmentApi: Record<API_TYPES, string> = {
  QUESTIONNAIRE_API: 'https://questionnaire-api-ch-elektra.os-dev.io/api',
  PROJECT_API: 'https://project-api-ch-elektra.os-dev.io/api',
  APP_NAMESPACE: 'APP_PROFILE_8a89631f-bb50-4cdf-869b-36032649cf00',
}

const productionApi: Record<API_TYPES, string> = {
  QUESTIONNAIRE_API: 'https://questionnaire-api-ch-wolverine.os-dev.io/api',
  PROJECT_API: 'https://project-api-ch-wolverine.os-dev.io/api',
  APP_NAMESPACE: '',
}

const config = Object.keys(API_TYPES).reduce((acc, type) => {
  const api =
    process.env.NODE_ENV !== 'development'
      ? process.env[`REACT_APP_${type}`] || productionApi[type as API_TYPES]
      : developmentApi[type as API_TYPES]
  return { ...acc, [type]: api }
}, {} as Record<API_TYPES, string>)

export default config
